<template>
<main class="news">
    <section class="news__item">
        <h2 class="news__item-title h2">v2.39 от 31 мая 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Настройка "Расходы ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены показатели "Локальный маркетинг" и "Федеральный маркетинг".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Юнит-экономика</h3>
                <ul class="news__item-list">
                    <li class="news__element">Удален столбец "Меньше 800", добавлены столбцы "OPEX" и "Ср.OPEX".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.38 от 17 мая 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Фильтры по ресторанам</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены фильтры по ресторанам в отчётах "Рейсы" и "Опасные операции".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Тайминг</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен в ввод настроек норм тайминга для разных зон доставки.</li>
                    <li class="news__element">В отчёте "Время. Доставка" данные по опозданиями теперь выводятся с учётом зон доставки.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Юнит-экономика</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь в юнит-экономике учитывается параметр "ГСМ" расходов ресторана.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.37 от 27 апреля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчёт "Время. Зал"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен фильтр по ресторанам.</li>
                    <li class="news__element">Добавлены показатели "Бар" и "Общее время", убран показатель "Время приготовления".</li>
                    <li class="news__element">Добавлена опция "Группировать цеха кухни", управляющая отображением дополнительных показателей. В включенном состоянии (по умолчанию) отображается сводный показатель "Кухня", значением которого является среднее время всех цехов кухни. В выключенном состоянии отображаются показатели, соответствующие отдельным цехам кухни.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.36 от 20 апреля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Возвращена настройка "Расходы ресторана" в измененном виде.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Фильтры по ресторанам</h3>
                <ul class="news__item-list">
                    <li class="news__element">Фильтры по ресторанам добавлены в отчеты по эффективности.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.35 от 14 апреля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлена настройка "Комиссии".</li>
                    <li class="news__element">Настройка "Расходы ресторана" заменена настройкой "ФОТ".</li>
                </ul>
            </li>
            <li class="news__element">Удален отчет "Unit-экономика 1".</li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.34 от 28 марта 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Время. Доставка"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлена фильтрация по зонам доставки.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.33 от 26 марта 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Время. Доставка"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен раздел "Распределение времени доставки".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.32 от 22 марта 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты по опасным операциям</h3>
                <ul class="news__item-list">
                    <li class="news__element">В общем отчете теперь есть разбивка по типам опасных операций.</li>
                    <li class="news__element">В детальном отчете теперь выводится только список конкретных опасных операций.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.31 от 17 марта 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты по доходам</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен фильтр по ресторанам.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.30 от 5 марта 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Эффективность" </h3>
                <ul class="news__item-list">
                    <li class="news__element">В таблицу добавлено поле "Курьеров в час".</li>
                    <li class="news__element">Числовые показатели теперь выводятся в формате с плавающей точкой, с точностью до сотых.</li>
                    <li class="news__element">Во всех таблицах числовые показатели теперь выравниваются по правому краю.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Эффективность 2" </h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен новый отчет "Эффективность 2", в котором выводится таблица ресторанов с показателями "Гостей на заказ", "Чек на гостя", "Блюд на гостя", "Цена блюда" и "Заказов на курьера" по всем типам сервисов за выбранный период.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Время. Зал" </h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен новый отчет "Время. Зал", в котором выводится таблица ресторанов с временами приготовления и оплаты за выбранный период.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты по времени</h3>
                <ul class="news__item-list">
                    <li class="news__element">Отчет "Тайминг" переименован в "Время. Доставка".</li>
                    <li class="news__element">Отчеты по времени теперь расположены в меню рядом, подобраны новые иконки.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Интерфейс</h3>
                <ul class="news__item-list">
                    <li class="news__element">Меню в мобильной и десктопной версиях теперь скроллится.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.29 от 2 марта 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен отчет "Блюда".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.28 от 21 февраля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен отчет "Unit-экономика 2".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.27 от 18 февраля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет по тональности</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлена возможность сравнения периодов.</li>
                    <li class="news__element">Обновлен интерфейс отчета.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.26 от 10 февраля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены отчеты по использованию.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.25 от 7 февраля 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">В отчетах "Время. Кухня" и "Время. Кухня ресторана" теперь можно выбирать тип сервиса.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.24 от 27 января 2022 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены отчеты "Время. Кухня" и "Время. Кухня ресторана".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.23 от 18 ноября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Опасные операции" и "Опасные операции ресторана".</h3>
                <ul class="news__item-list">
                    <li class="news__element">В таблицу ресторанов отчета "Опасные операции" добавлено поле "Сумма".</li>
                    <li class="news__element">В общую таблицу событий отчета "Опасные операции ресторана" добавлено поле "Сумма".</li>
                    <li class="news__element">В детальную таблицу событый отчета "Опасные операции ресторана" добавлены поля "Пользователь", "Доп. авторизация", "Официант", "№ смены", "№ заказа", "№ стола", "Кол-во гостей", "Блюда", "Кол-во блюд", "Причина", "Сумма" и "Комментарий".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.22 от 12 ноября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены отчеты "Опасные операции" и "Опасные операции ресторана".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.21 от 18 октября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен ввод расходов ресторана.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.20 от 14 октября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Unit-экономика"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлена таблица заказов.</li>
                    <li class="news__element">Добавлены графики.</li>
                    <li class="news__element">Теперь можно смотреть unit-экономику источников.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.19 от 1 октября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены отчеты "Unit-экономика" и "Unit-экономика ресторана".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.18 от 24 сентября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки</h3>
                <ul class="news__item-list">
                    <li class="news__element">В настройках теперь можно управлять добавлять, удалять и изменять пользователей.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.17 от 14 сентября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчёты по эффективности</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены отчеты "Эффективность" и "Эффективность ресторана".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчёты по доходам</h3>
                <ul class="news__item-list">
                    <li class="news__element">В отчеты по доходам добавлены параметры "С собой", "Самовынос".</li>
                    <li class="news__element">Количество заказов в доходах теперь считается корректно, средний чек пересчитан.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.16 от 13 сентября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчёты по персоналу</h3>
                <ul class="news__item-list">
                    <li class="news__element">В отчетах "Аттестация", "Аттестация подразделения", "Штатная расстановка" и "Штатная расстановка подразделения" теперь доступна фильтрация по типу должностей (управляющий или линейный персонал).</li>
                    <li class="news__element">В отчетах "Аттестация" и "Штатная расстановка" при выборе значения фильтра "управляющий персонал" или "линейный персонал" не отображаются подразделения, которые не являются торговыми точками (Офис, Производство, Доставка, Склад) и подразделения, в которых нет персонала выбранного типа.</li>
                    <li class="news__element">В отчетах "Аттестация подразделения" и "Штатная расстановка подразделения" для подразделений, не являющихся торговыми точками, фильтр по типу должностей персонала не выводится.</li>
                    <li class="news__element">Во всех отчетах по персоналу поле и направление сортировки теперь сохраняются при переходе между страницами.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.15 от 9 сентября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Системные страницы</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлена страница "Ошибка 404" для перенаправления авторизованных пользователей при запросе несуществующих ресурсов.</li>
                    <li class="news__element">Добавлена страница "Ошибка 401" для перенаправления авторизованных пользователей при запросе ресурсов, к которым у них нет доступа.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки доступа</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь, если у пользователя нет доступа к отчету/ресторану, он перенаправляется на страницу "Ошибка 401", а не "Новости".</li>
                    <li class="news__element">Страница "Новости" теперь доступна только для администраторов.</li>
                    <li class="news__element">У всех пользователей, кроме администраторов, удалены права на методы API set/timing, get/timing, set/income, get/income. Благодаря этому раздел "Настройки" в навбаре виден только администраторам.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.14 от 8 сентября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Рейсы" и "Рейсы ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">В отчёты добавлена возможность сравнения двух периодов.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.13 от 3 сентября 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Тайминг" и "Тайминг ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Раздел "Процент опозданий" переименован в "Опоздания".</li>
                    <li class="news__element">Таблица в разделе "Опоздания" теперь содержит отдельные сортируемые стобцы для числа опозданий.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Разное</h3>
                <ul class="news__item-list">
                    <li class="news__element">Между числом и символом процента теперь нет пробела</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.12 от 30 августа 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлены отчеты "Рейсы" и "Рейсы ресторана".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен раздел "Пользователи" с таблицей зарегистрированных пользователей.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Настройки доступа</h3>
                <ul class="news__item-list">
                    <li class="news__element">В боковом меню теперь отображаются только те разделы, к которым у пользователя есть доступ. Раздел "Настройки" отображается в боковом меню только если у пользователя есть права хотя бы на один из подразделов.</li>
                    <li class="news__element">Если пользователь пытается открыть по прямой ссылке ресурс (например общий отчет, детальный отчет по ресторану/подразделению, или подраздел настроек), к которому у него нет доступа, то он перенаправляется на страницу "Новости".</li>
                    <li class="news__element">Подразделения отчетов "Аттестация" и "Штатная расстановка", которые не являются ресторанами (например, "Офис", "Производство" и т.д.), теперь доступны только для админов.</li>
                    <li class="news__element">Теперь, если у пользователя есть доступ только к одному ресторану/подразделению, то при открытии общего отчета, он перенаправляется на детальный отчет по этому ресторану/подразделению сразу, а не после получения данных.</li>
                    <li class="news__element">Теперь при открытии детального отчета по ресторану/подразделению его название отображается в заголовке страницы сразу, а не после получения данных.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.11 от 28 июня 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Тональность" и "Тональность ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">В отчеты добавлен режим "Классификация" с таблицей категорий жалоб и графиком их распределения.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.10 от 26 июня 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Страница "Настройки", раздел "Нормы тайминга"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен раздел, в котором можно установить дефолтные нормы тайминга и нормы для каждого конкретного месяца. Дефолтные значения будут использоваться для тех месяцев, у которых нормы тайминга не были введены вручную.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.9 от 17 июня 2021 г.</h2>
        <ul class="news__item-list">
            <li>Добавлен отчет "Тональность ресторана"</li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.8 от 11 июня 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Страница "Настройки", раздел "План доходов"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Исправлены баги сохранения данных, улучшена вёрстка и валидация вводимых значений.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.7 от 7 июня 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты "Доходы" и "Доходы ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Изменён режим динамика. Теперь период динамики может быть любым периодом внутри периода отчета. Данные периода динамики сравниваются с ожидаемыми значениями, рассчитанными на основе данных периода отчета.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.6 от 1 июня 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">Теперь при открытии общего отчета, если у пользователя есть права только на один ресторан (подразделение), то он перенаправляется на детальный отчет по этому ресторану/подразделению</li>
            <li class="news__element">Внешний вид всех отчетов унифицирован.</li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.5 от 31 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Тайминг"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь период отчета по умолчанию - с первого числа текущего месяца до вчерашнего дня.</li>
                    <li class="news__element">Теперь режим ("Среднее время", "Процент опозданий" и "Распределение опозданий по часам") выбираются для всего отчета целиком, а не для каждого ресторана отдельно.</li>
                    <li class="news__element">Теперь в режимах "Среднее время" и "Процент опозданий" тайминги ресторанов выводятся в виде сортируемой таблицы.</li>
                    <li class="news__element">Теперь если у пользователя есть права только на один ресторан, он перенаправляется на детальный отчет этого ресторана.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Тайминг ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь период отчета по умолчанию - с первого числа текущего месяца до вчерашнего дня.</li>
                    <li class="news__element">Теперь режим ("Среднее время" и "Процент опозданий") выбираются для всего отчета целиком, а не для каждого курьера отдельно.</li>
                    <li class="news__element">Теперь в режимах "Среднее время" и "Процент опозданий" тайминги курьеров выводятся в виде сортируемой таблицы.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.4 от 26 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Тональность"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь период отчета по умолчанию - с первого числа текущего месяца до вчерашнего дня.</li>
                    <li class="news__element">Рестораны/источники/направления тональности теперь выводятся в виде сортируемой таблицы. Поле и направление сортировки в таблице оказывают влияние на сортировку данных на графике.</li>
                    <li class="news__element">Добавлен блок "Итого", который отображается при просмотре тональности по ресторанам, если у пользователя более одного ресторана.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.3 от 25 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Страница "Настройки"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Страница "Настройки" доступна только админам.</li>
                    <li class="news__element">Теперь после ввода плана ресторана на странице "Настройки" данные отчетов "Доходы" и "Доходы ресторанов" обновляются.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.2 от 25 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Штатная расстановка"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Cтолбец "Свободно" переименован в "Вакансий".</li>
                    <li class="news__element">Из таблицы подразделений убран ряд "Итого" и перенесен наверх отдельным блоком. Если у пользователя есть права только на 1 подразделение, то этот блок не показывается.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Штатная расстановка подразделения"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Cтолбец "Свободно" переименован в "Вакансий".</li>
                    <li class="news__element">Из таблицы подразделений убран ряд "Итого" и перенесен наверх отдельным блоком. Добавлен столбец "Ставка" с соответствующими данными. </li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.1 от 25 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Аттестация"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Таблица подразделений в отчете "Аттестация" теперь сортируется по столбцу "Аттестованых".</li>
                    <li class="news__element">Из таблицы подразделений убран ряд "Итого" и перенесен наверх отдельным блоком. Если у пользователя есть права только на 1 подразделение, то этот блок не показывается.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Аттестация подразделения"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Переименованы столбцы таблицы: "Средняя успеваемой" -> "Средний результат", "Дата валидации" -> "Дата аттестации", "Результат" -> "Аттестован", "Процент" -> "Результат".</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v2.0 от 23 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">Добавлен вход по логину и паролю.</li>
            <li class="news__element">Добавлена страница <router-link class="link link_red" :to="{ name: 'Settings' }">"Настройки"</router-link>, где можно ввести план дохода ресторана.</li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v1.5 от 19 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">Добавлены отчеты <router-link class="link link_red" :to="{ name: 'StaffTotal' }">"Штатная расстановка"</router-link> и "Штатная расстановка подразделения".</li>
            <li class="news__element">
                <h3 class="news__element-title h3">Общие правки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Отчет "Валидация" переименован в "Аттестация".</li>
                    <li class="news__element">Отчет "Валидация группы" переименован в "Аттестация подразделения".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Аттестация"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Таблицу теперь можно сортировать и по истонику данных.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Доходы"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Таблицу в режиме "Базовые данные" теперь можно сортировать.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v1.4 от 18 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">Добавлены отчеты <router-link class="link link_red" :to="{ name: 'AttestationTotal' }">"Валидация"</router-link> и "Валидация подразделения"</li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v1.3 от 17 мая 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Общие правки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Календарь с выбором интервала дат открывается в модальном окне.</li>
                    <li class="news__element">Цвета в графиках - это исключительно оттенки красного.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Доходы"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Денежные значения не округляются до целого.</li>
                    <li class="news__element">Добавлены различные режимы отчета.</li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Базовые данные"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Итоговые данные и данные ресторанов отображаются в виде таблицы.</li>
                            <li class="news__element">Параметры "Гостей/заказов" и "Средний чек" заменены на "Выручка доставки" и "Выручка зала".</li>
                            <li class="news__element">Для значений "Выручка доставки" и "Выручка зала" указывается процент от "Общей выручки".</li>
                        </ul>
                    </li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Сравнение с планом"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Новый режим отчета, в котором для выбранного периода фактические значения общей выручки и прогноза выручки сравниваются с планом.</li>
                        </ul>
                    </li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Сравнение с другим периодом"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Функциональность сравнения показателей за два периода выделена в отдельный режим.</li>
                        </ul>
                    </li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Динамика"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Новый режим отчета, в котором значения показателей выбранного периода сравниваются с соответствующими значениями выбранного подпериода (последний день или неделя).</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Доходы ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь в отчете можно посмотреть <router-link class="link link_red" :to="{ name: 'IncomeRestaurant', params: { id: 'total' } }" title="Смотреть общий доход по всем ресторанам">итоговые данные</router-link> по всем ресторанам за выбранный период.</li>
                    <li class="news__element">Добавлены различные режимы отчета.</li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Сравнение с планом"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Новый режим отчета, в котором для выбранного периода фактические значения общей выручки и прогноза выручки сравниваются с планом.</li>
                        </ul>
                    </li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Сравнение с другим периодом"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Функциональность сравнения показателей за два периода выделена в отдельный режим.</li>
                        </ul>
                    </li>
                    <li class="news__element">
                        <h4 class="news__element-title h4">Режим "Динамика"</h4>
                        <ul class="news__item-list">
                            <li class="news__element">Новый режим отчета, в котором значения показателей выбранного периода сравниваются с соответствующими значениями выбранного подпериода (последний день или неделя).</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v1.2 от 26 апреля 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Общие правки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен отчет "Тональность"</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v1.1 от 12 апреля 2021 г. 🚀</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты по таймингам</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь данные организованы в виде вкладок.</li>
                    <li class="news__element">Добавлены данные по процентам опозданий.</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Тайминг по ресторанам"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Добавлен график распределения опозданий по часам.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v1.0 от 8 апреля 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Общие правки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Во всех отчетах рядом с данными теперь отображается период отчета.</li>
                    <li class="news__element">Страницы "История изменений" переименована в "Новости".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчеты по таймингам</h3>
                <ul class="news__item-list">
                    <li class="news__element">Теперь во отчетах по таймингам сравниваются не данные двух заданных периодов, а данные одного выбранного периода с нормой.</li>
                    <li class="news__element">Параметры отчетов переименованы.</li>
                    <li class="news__element">Отчет "Тайминг" переименован в "Тайминг по ресторанам".</li>
                    <li class="news__element">Отчет "Тайминг ресторана" переименован в "Тайминг по курьерам ресторана".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Доходы ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Изменены цвета в графиках отчета.</li>
                    <li class="news__element">В шапку отчета добавлена ссылка "Вернуться к общим доходам".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Тайминг по курьерам ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">В шапку отчета добавлена ссылка "Вернуться к таймингу по ресторанам".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Боковое меню</h3>
                <ul class="news__item-list">
                    <li class="news__element">Обновлён набор иконок.</li>
                    <li class="news__element">Ссылка на страницу "Новости" теперь отделяется от ссылок на отчеты горизонтальной чертой.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="news__item">
        <h2 class="news__item-title h2">v0.1 от 31 марта 2021 г.</h2>
        <ul class="news__item-list">
            <li class="news__element">
                <h3 class="news__element-title h3">Общие правки</h3>
                <ul class="news__item-list">
                    <li class="news__element">Вёрстка всех отчетов переведена на универсальные компоненты с общими стилями.</li>
                    <li class="news__element">Заголовок элемента отчета теперь отображается на отдельной строке.</li>
                    <li class="news__element">Теперь во всех отчетах элемент "Итого" располагается вначале.</li>
                    <li class="news__element">В боковой навигации скрыты ссылки на страницы, находящиеся в разработке.</li>
                    <li class="news__element">Добавлена страница "История изменений".</li>
                </ul>
            </li>
            <li class="news__element">
                <h3 class="news__element-title h3">Отчет "Доходы ресторана"</h3>
                <ul class="news__item-list">
                    <li class="news__element">Численные значения на графиках теперь форматируются корректно.</li>
                    <li class="news__element">В всплывающей подсказке для графиков "Распределение выручки" и "Распределение заказов" теперь отображается процент текущего значения от итогового.</li>
                    <li class="news__element">В графике "Средний чек" цвет колонки "Итого" изменен со светло-голубого на чёрный.</li>
                </ul>
            </li>
        </ul>
    </section>
</main>
</template>

<script>
export default {
    name: "News"
}
</script>

<style lang="scss">
.news__item-title  {
    margin-bottom: 10px;
}
.news__item {
    max-width: 720px;

    & + & {
        margin-top: 30px;
    }
}
.news__item-list {
    list-style: initial;
    padding-left: 25px;
}
.news__element-title {
    margin: 5px 0;
}
</style>
